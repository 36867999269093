var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"acn-job-detail p-3"},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.jobTitle))]),_c('display-data',{attrs:{"label":"Agent"}},[(_vm.job.user)?_c('job-handler',{staticClass:"bordered-card p-2 mt-1",attrs:{"user":_vm.job.user},scopedSlots:_vm._u([{key:"end",fn:function(){return [(_vm.canRemoveHandler)?_c('fd-button',{staticClass:"bordered danger",on:{"click":function($event){return _vm.$emit('removeHandler', _vm.job)}}},[_vm._v(" Remove ")]):_vm._e()]},proxy:true}],null,false,155906880)}):_c('div',[_vm._v("Unassigned")])],1),_c('display-data',{attrs:{"label":"Commission","content":_vm.commission}}),(_vm.isPayByCommission)?_c('display-data',{attrs:{"label":"Estimated Amount","content":_vm.getJobEstimatedAmount({
        listingCommission: _vm.listingCommission,
        specialistCommission: _vm.specialistCommission,
        jobRates: _vm.job.rates,
        salePrice: _vm._.get(_vm.listing, 'property.salePrice')
      })}}):_vm._e(),(_vm.isForCloser && !_vm.job.user && _vm.isSpecialist)?_c('div',[_vm._t("assign-closer")],2):_vm._e(),(_vm._.get(_vm.job, 'acnJobScope.description'))?_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm._.get(_vm.job, "acnJobScope.description"))+" ")]):_vm._e(),(_vm.canShowApplication)?_c('div',{staticClass:"bordered-card p-2 mb-2"},[_c('div',{staticClass:"lineBottom pb-1 mb-3"},[_vm._v("Pending Applications")]),(_vm.isApplicationsLoading)?_c('p',{staticClass:"text-center"},[_vm._v(" Loading Applications... ")]):(_vm.applications.length < 1)?_c('p',{staticClass:"text-center"},[_vm._v(" No pending applications ")]):_c('div',{style:({ maxHeight: '300px', overflowY: 'auto' })},_vm._l((_vm.applications),function(application){return _c('JobHandler',{key:application.id,staticClass:"mb-2",attrs:{"user":application.user},scopedSlots:_vm._u([{key:"end",fn:function(){return [(_vm.canManageApplication)?_c('div',{staticClass:"d-flex ml-2"},[_c('fd-button',{staticClass:"main flat ml-1",on:{"click":function($event){return _vm.$emit('approveApplication', application)}}},[_c('i',{staticClass:"fas fa-check"})]),_c('fd-button',{staticClass:"danger flat ml-1",on:{"click":function($event){return _vm.$emit('rejectApplication', application)}}},[_c('i',{staticClass:"fas fa-times"})])],1):_vm._e()]},proxy:true}],null,true)})}),1)]):_vm._e(),(_vm.job.isCompleted)?_c('div',[_c('hr',{staticClass:"mb-3"}),_c('div',{staticClass:"bg-success fg-white font-bold mb-2",style:({
        borderRadius: '50rem',
        width: 'fit-content',
        fontSize: '14px',
        padding: '6px 18px'
      })},[_vm._v(" Completed ")]),_c('display-data',{attrs:{"label":"remark","content":_vm.job.remarkForCompleted}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.canEditJob)?_c('fd-button',{staticClass:"bordered main",on:{"click":function($event){return _vm.$emit('editJob', _vm.job)}}},[_vm._v(" Edit Job ")]):_vm._e(),(_vm.canDeleteJob)?_c('fd-button',{staticClass:"bordered danger ml-1",on:{"click":function($event){return _vm.$emit('deleteJob', _vm.job.id)}}},[_vm._v(" Delete Job ")]):_vm._e(),(_vm.isRegistrant && _vm.isForSpecialist && !_vm.hasHandler)?_c('fd-button',{staticClass:"main ml-1",on:{"click":function($event){return _vm.$emit('becomeSpecialist')}}},[_vm._v(" Become Specialist ")]):_vm._e(),(_vm.canCompleteJob)?_c('fd-button',{staticClass:"main ml-1",on:{"click":function($event){return _vm.$emit('completeJob', _vm.job)}}},[_vm._v(" Complete Job ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }