<template>
  <div class="acn-job-detail p-3">
    <h3 class="mb-4">{{ jobTitle }}</h3>

    <display-data label="Agent">
      <job-handler
        v-if="job.user"
        :user="job.user"
        class="bordered-card p-2 mt-1"
      >
        <template #end>
          <fd-button
            v-if="canRemoveHandler"
            class="bordered danger"
            @click="$emit('removeHandler', job)"
          >
            Remove
          </fd-button>
        </template>
      </job-handler>
      <div v-else>Unassigned</div>
    </display-data>

    <display-data label="Commission" :content="commission"></display-data>

    <display-data
      v-if="isPayByCommission"
      label="Estimated Amount"
      :content="
        getJobEstimatedAmount({
          listingCommission: listingCommission,
          specialistCommission: specialistCommission,
          jobRates: job.rates,
          salePrice: _.get(listing, 'property.salePrice')
        })
      "
    ></display-data>

    <!-- Assign Closer -->
    <div v-if="isForCloser && !job.user && isSpecialist">
      <slot name="assign-closer"></slot>
    </div>

    <p v-if="_.get(job, 'acnJobScope.description')" class="mb-2">
      {{ _.get(job, "acnJobScope.description") }}
    </p>

    <div v-if="canShowApplication" class="bordered-card p-2 mb-2">
      <div class="lineBottom pb-1 mb-3">Pending Applications</div>
      <p v-if="isApplicationsLoading" class="text-center">
        Loading Applications...
      </p>
      <p v-else-if="applications.length < 1" class="text-center">
        No pending applications
      </p>
      <div v-else :style="{ maxHeight: '300px', overflowY: 'auto' }">
        <JobHandler
          v-for="application in applications"
          :key="application.id"
          :user="application.user"
          class="mb-2"
        >
          <template #end>
            <div v-if="canManageApplication" class="d-flex ml-2">
              <fd-button
                class="main flat ml-1"
                @click="$emit('approveApplication', application)"
              >
                <i class="fas fa-check"></i>
              </fd-button>
              <fd-button
                class="danger flat ml-1"
                @click="$emit('rejectApplication', application)"
              >
                <i class="fas fa-times"></i>
              </fd-button>
            </div>
          </template>
        </JobHandler>
      </div>
    </div>

    <div v-if="job.isCompleted">
      <hr class="mb-3" />
      <div
        class="bg-success fg-white font-bold mb-2"
        :style="{
          borderRadius: '50rem',
          width: 'fit-content',
          fontSize: '14px',
          padding: '6px 18px'
        }"
      >
        Completed
      </div>
      <display-data
        label="remark"
        :content="job.remarkForCompleted"
      ></display-data>
    </div>

    <!-- ACTIONS -->
    <div class="d-flex justify-content-end">
      <fd-button
        v-if="canEditJob"
        class="bordered main"
        @click="$emit('editJob', job)"
      >
        Edit Job
      </fd-button>
      <fd-button
        v-if="canDeleteJob"
        class="bordered danger ml-1"
        @click="$emit('deleteJob', job.id)"
      >
        Delete Job
      </fd-button>
      <fd-button
        v-if="isRegistrant && isForSpecialist && !hasHandler"
        class="main ml-1"
        @click="$emit('becomeSpecialist')"
      >
        Become Specialist
      </fd-button>
      <fd-button
        v-if="canCompleteJob"
        class="main ml-1"
        @click="$emit('completeJob', job)"
      >
        Complete Job
      </fd-button>
    </div>
  </div>
</template>

<script>
import { acnPaymentType } from "@/enums";
import useACNRates from "@/modules/ACN/composables/useACNRates";

export default {
  setup() {
    const { getJobCalculatedRate, getJobEstimatedAmount } = useACNRates();
    return { getJobCalculatedRate, getJobEstimatedAmount };
  },
  components: {
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    JobHandler: () => import("@/modules/ACN/components/JobHandler")
  },
  mixins: [],
  props: {
    job: {
      type: Object,
      required: true
    },
    isApplicationsLoading: {
      type: Boolean,
      default: false
    },
    applications: {
      type: Array,
      default: () => []
    },
    isRegistrant: {
      type: Boolean,
      default: false
    },
    isSpecialist: {
      type: Boolean,
      default: false
    },
    specialistCommission: {
      type: [String, Number],
      default: 0
    },
    listingCommission: {
      type: [String, Number],
      default: 0
    },
    listing: {
      type: Object
    }
  },
  data: function () {
    return {};
  },
  computed: {
    // Condition checkings
    isMe() {
      return this.job.user?.id == this.$auth.user().id;
    },
    isForRegistrant() {
      return this.job.isForRegistrant;
    },
    isForSpecialist() {
      return this.job.isForSpecialist;
    },
    isForCloser() {
      return this.job.isForCloser;
    },
    hasHandler() {
      return !!this.job.user;
    },
    // Access Control
    canShowApplication() {
      return (
        (this.isRegistrant || this.isSpecialist) &&
        !this.job.user &&
        !this.isForCloser
      );
    },
    canManageApplication() {
      if (this.isForSpecialist) {
        return this.isRegistrant;
      } else {
        return this.isSpecialist;
      }
    },
    canDeleteJob() {
      return this.canEditJob && !this.hasHandler;
    },
    canEditJob() {
      return (
        this.isSpecialist &&
        !this.isForSpecialist &&
        !this.isForRegistrant &&
        !this.isForCloser &&
        !this.job.isCompleted
      );
    },
    canCompleteJob() {
      return (
        this.isSpecialist &&
        !this.isForSpecialist &&
        !this.isForRegistrant &&
        !this.isForCloser &&
        this.hasHandler &&
        !this.job.isCompleted
      );
    },
    canRemoveHandler() {
      return (
        this.hasHandler &&
        !this.isForRegistrant &&
        !this.isForSpecialist &&
        !this.isForCloser &&
        !this.job.isCompleted
      );
    },
    // Job Related
    isPayByCommission() {
      return this.job.paymentType === acnPaymentType.COMMISSION;
    },
    jobTitle() {
      if (this.job.isForRegistrant) return "Registrant";
      if (this.job.isForSpecialist) return "Specialist";
      if (this.job.isForCloser) return "Closer";

      return this.job.acnJobScope.title;
    },
    commission() {
      if (this.isPayByCommission) {
        if (this.isForRegistrant || this.isForSpecialist || this.isForCloser) {
          return `${this.job.rates}%`;
        }

        let rate = this.getJobCalculatedRate(
          this.specialistCommission,
          this.job.rates
        );

        return `${rate}%`;
      }
      return `Fixed Rate RM${this.job.rates}`;
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
